import axios from "axios";

import BaseModule from '@/store/base-module'

export interface Media {
  sponsoredContent: boolean;
  _id: string;
}
export interface AccountState {
  selectedId: string | null;
  documents: {
    [_id: string]: Account;
  };
}

export default BaseModule({
  name: 'media',
  actions: {
    async loadForProject(context, projectId){
      try{
        const response = await axios.get(`/api/media/for-project/${projectId}`)
        if (response && response.data) {
          context.commit('CREATE_BULK', response.data)
          return response.data;
        }
      }catch(e){
        return e;
      }
    }
  },
  getters: {
    forAccount: (state, getters) => accountId => {
      return getters.all.filter(object => object.account === accountId || false).sort((a,b) => a.position - b.position)
    },
    forCollab: (state, getters) => collabId => {
      return getters.all.filter(object => object.collab === collabId || false).sort((a,b) => a.position - b.position)
    },
    sortedByPosition: (state, getters) => {
      return [...getters.all].sort((a, b) => a.position - b.position)
    },
    featuredByAccount: (state, getters) => {
      const accounts = {};
      getters.sortedByPosition.forEach(media => {
        if(!Object.prototype.hasOwnProperty.call(accounts, media.account) && !media.error){
          accounts[media.account] = media;
        }
      })
      return accounts;
    },
    forProject: (state, getters, rootState, rootGetters) => projectId => {
      const collabsForProject = (rootGetters['collab/byProject'][projectId] || []).map(collab => collab._id);
	  const mediaForProject = getters.all.filter(media => media.project === projectId);
      return getters.all.filter(media => (
		media.collab && collabsForProject.includes(media.collab) ||
		media.project === projectId
	  ))
    },
    error: (state, getters) => {
      return getters.all.filter(object => (
		object.subscribedToVision?.toLowerCase().includes('error') ||
          !object.thumbnail // get media with no thumbnails
      ))
    }
  }
})